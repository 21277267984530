require.context("../images", true, /\.(gif|jpg|png|svg)$/i);

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"

Rails.start()
ActiveStorage.start()

import "../stylesheets/application.css";
import "../stylesheets/application.scss";

import "alpinejs";

import "../src/utils";

import multiSearch from "../src/multiSearch";

window.multiSearch = multiSearch;
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
